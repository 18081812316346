var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10"}),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"btn-group float-right"},[(
              _vm.$store.getters.can(
                'tif.proyecciones.saveDetallePlantaExterna'
              ) &&
                this.viajes &&
                this.viajes.length > 0
            )?_c('button',{staticClass:"btn bg-info shadow",attrs:{"disabled":_vm.$parent.bloq ||
                [1, 2].includes(
                  _vm.$parent.$refs.tifProyeccionTransporteInterno
                    .visualizar_proyeccion
                )},on:{"click":function($event){return _vm.saveDetalle()}}},[_c('i',{staticClass:"fas fa-sync-alt fa-spin"}),_vm._v(" Guardar Detalles ")]):_vm._e(),_c('button',{staticClass:"btn bg-primary ml-1 text-white shadow",staticStyle:{"cursor":"pointer"},attrs:{"type":"button","id":"agregarViaje","disabled":[1, 2].includes(_vm.visualizar_viaje) ||
                _vm.$parent.bloq ||
                [1, 2].includes(
                  _vm.$parent.$refs.tifProyeccionTransporteInterno
                    .visualizar_proyeccion
                )},on:{"click":function($event){return _vm.addViaje()}}},[_c('i',{staticClass:"fas fa-plus"})])])])]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"table-responsive mt-2"},[_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm",staticStyle:{"font-size":"90%"}},[_vm._m(1),_c('tbody',[_vm._l((_vm.viajes),function(via,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-center"},[(_vm.visualizar_viaje != 2)?_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn bg-navy",attrs:{"type":"button","disabled":_vm.visualizar_viaje != -1 ||
                        [1, 2].includes(
                          _vm.$parent.$refs.tifProyeccionTransporteInterno
                            .visualizar_proyeccion
                        )},on:{"click":function($event){return _vm.editViaje(index)}}},[_c('i',{staticClass:"fas fa-edit"})]),_c('button',{staticClass:"btn bg-danger",attrs:{"disabled":_vm.visualizar_viaje != -1 ||
                        [1, 2].includes(
                          _vm.$parent.$refs.tifProyeccionTransporteInterno
                            .visualizar_proyeccion
                        )},on:{"click":function($event){return _vm.destroyViaje(index)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e(),(
                    _vm.visualizar_viaje == 2 && _vm.indice_actualizar_viaje == index
                  )?_c('div',{staticClass:"btn-group"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.viaje.$invalid),expression:"!$v.viaje.$invalid"}],staticClass:"btn bg-success",attrs:{"type":"button"},on:{"click":function($event){return _vm.saveUpdateViaje(index)}}},[_c('i',{staticClass:"fas fa-save"})]),_c('button',{staticClass:"btn bg-danger",on:{"click":function($event){return _vm.cancelViaje()}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e(),(
                    _vm.visualizar_viaje == 2 && _vm.indice_actualizar_viaje !== index
                  )?_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn bg-navy",attrs:{"type":"button","disabled":""},on:{"click":function($event){return _vm.editViaje(index)}}},[_c('i',{staticClass:"fas fa-edit"})]),_c('button',{staticClass:"btn bg-danger",attrs:{"disabled":""},on:{"click":function($event){return _vm.destroyViaje(index)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()]),_c('td',[(
                    _vm.indice_actualizar_viaje === index &&
                      _vm.visualizar_viaje === 2
                  )?_c('div',[_c('v-select',{class:[
                      _vm.$v.viaje.producto_id.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                      'form-control form-control-sm p-0',
                    ],attrs:{"placeholder":"Nombre","label":"nombre","options":_vm.listasForms.productos},on:{"input":function($event){return _vm.selectProducto()}},model:{value:(_vm.producto),callback:function ($$v) {_vm.producto=$$v},expression:"producto"}})],1):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(via.producto.nombre)+" ")])]),_c('td',[(
                    _vm.indice_actualizar_viaje === index &&
                      _vm.visualizar_viaje === 2
                  )?_c('div',[_c('v-select',{class:[
                      _vm.$v.viaje.tipo_vehiculo_id.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                      'form-control form-control-sm p-0',
                    ],attrs:{"placeholder":"Tipo Vehículo","label":"nombre","options":_vm.listasForms.tipo_vehiculos},on:{"input":function($event){return _vm.selectProducto()}},model:{value:(_vm.tipo_vehiculo),callback:function ($$v) {_vm.tipo_vehiculo=$$v},expression:"tipo_vehiculo"}})],1):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(via.tipo_vehiculo.nombre)+" ")])]),_c('td',[(
                    _vm.indice_actualizar_viaje === index &&
                      _vm.visualizar_viaje === 2
                  )?_c('div',[_c('v-select',{class:[
                      _vm.$v.viaje.empresa_id.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                      'form-control form-control-sm p-0',
                    ],attrs:{"placeholder":"Nombre","label":"razon_social","options":_vm.listasForms.empresas},on:{"input":function($event){return _vm.selectEmpresa()}},model:{value:(_vm.empresa),callback:function ($$v) {_vm.empresa=$$v},expression:"empresa"}})],1):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(via.empresa.razon_social)+" ")])]),_c('td',[(
                    _vm.indice_actualizar_viaje === index &&
                      _vm.visualizar_viaje === 2
                  )?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.viaje.cant_vh),expression:"viaje.cant_vh"}],staticClass:"form-control form-control-sm",class:_vm.$v.viaje.cant_vh.$invalid ? 'is-invalid' : 'is-valid',attrs:{"type":"number","placeholder":"Cant Vh"},domProps:{"value":(_vm.viaje.cant_vh)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.viaje, "cant_vh", $event.target.value)}}})]):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(via.cant_vh)+" ")])]),_c('td',[(
                    _vm.indice_actualizar_viaje === index &&
                      _vm.visualizar_viaje === 2
                  )?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.viaje.barriles_servicio),expression:"viaje.barriles_servicio"}],staticClass:"form-control form-control-sm",class:_vm.$v.viaje.barriles_servicio.$invalid
                        ? 'is-invalid'
                        : 'is-valid',attrs:{"type":"number","placeholder":"Bls"},domProps:{"value":(_vm.viaje.barriles_servicio)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.viaje, "barriles_servicio", $event.target.value)},function($event){[_vm.calcGalonesViaje(), _vm.totales()]}]}})]):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(via.barriles_servicio)+" ")])]),_c('td',{staticClass:"text-center"},[(
                    _vm.indice_actualizar_viaje === index &&
                      _vm.visualizar_viaje === 2
                  )?_c('div',[_vm._v(" "+_vm._s(_vm.viaje.galones)+" ")]):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(via.galones)+" ")])]),_c('td',{staticClass:"text-center"},[(
                    _vm.indice_actualizar_viaje === index &&
                      _vm.visualizar_viaje === 2
                  )?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.viaje.valor_tarifa),expression:"viaje.valor_tarifa"}],staticClass:"form-control form-control-sm",class:_vm.$v.viaje.valor_tarifa.$invalid
                        ? 'is-invalid'
                        : 'is-valid',attrs:{"type":"number","placeholder":"Tarifa"},domProps:{"value":(_vm.viaje.valor_tarifa)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.viaje, "valor_tarifa", $event.target.value)},function($event){return _vm.totales()}]}})]):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(via.valor_tarifa)+" ")])]),_c('td',{staticClass:"text-center"},[(
                    _vm.indice_actualizar_viaje === index &&
                      _vm.visualizar_viaje === 2
                  )?_c('div',[_vm._v(" "+_vm._s(_vm.viaje.total_valor_format)+" ")]):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(via.total_valor_format)+" ")])])])}),(_vm.visualizar_viaje === 1)?_c('tr',[_c('td',[_c('div',{staticClass:"btn-group float-right"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.viaje.$invalid),expression:"!$v.viaje.$invalid"}],staticClass:"btn bg-success",on:{"click":function($event){return _vm.saveViaje()}}},[_c('i',{staticClass:"fas fa-save"})]),_c('button',{staticClass:"btn bg-danger",on:{"click":function($event){return _vm.cancelViaje()}}},[_c('i',{staticClass:"fas fa-trash"})])])]),_c('td',[_c('v-select',{class:[
                    _vm.$v.viaje.producto_id.$invalid ? 'is-invalid' : 'is-valid',
                    _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                    'form-control form-control-sm p-0',
                  ],attrs:{"placeholder":"Producto","label":"nombre","options":_vm.listasForms.productos},on:{"input":function($event){return _vm.selectProducto()}},model:{value:(_vm.producto),callback:function ($$v) {_vm.producto=$$v},expression:"producto"}})],1),_c('td',[_c('v-select',{class:[
                    _vm.$v.viaje.tipo_vehiculo_id.$invalid
                      ? 'is-invalid'
                      : 'is-valid',
                    _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                    'form-control form-control-sm p-0',
                  ],attrs:{"placeholder":"Tipo Vehículo","label":"nombre","options":_vm.listasForms.tipo_vehiculos},on:{"input":function($event){return _vm.selectTipoVehiculo()}},model:{value:(_vm.tipo_vehiculo),callback:function ($$v) {_vm.tipo_vehiculo=$$v},expression:"tipo_vehiculo"}})],1),_c('td',[_c('v-select',{class:[
                    _vm.$v.viaje.empresa_id.$invalid ? 'is-invalid' : 'is-valid',
                    _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                    'form-control form-control-sm p-0',
                  ],attrs:{"placeholder":"Empresa","label":"razon_social","options":_vm.listasForms.empresas},on:{"input":function($event){return _vm.selectEmpresa()}},model:{value:(_vm.empresa),callback:function ($$v) {_vm.empresa=$$v},expression:"empresa"}})],1),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.viaje.cant_vh),expression:"viaje.cant_vh"}],staticClass:"form-control form-control-sm",class:_vm.$v.viaje.cant_vh.$invalid ? 'is-invalid' : 'is-valid',attrs:{"type":"number","placeholder":"Cant Vh"},domProps:{"value":(_vm.viaje.cant_vh)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.viaje, "cant_vh", $event.target.value)}}})]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.viaje.barriles_servicio),expression:"viaje.barriles_servicio"}],staticClass:"form-control form-control-sm",class:_vm.$v.viaje.barriles_servicio.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"number","placeholder":"Bls"},domProps:{"value":(_vm.viaje.barriles_servicio)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.viaje, "barriles_servicio", $event.target.value)},function($event){[_vm.calcGalonesViaje(), _vm.totales()]}]}})]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.viaje.galones)+" ")]),_c('td',{staticClass:"text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.viaje.valor_tarifa),expression:"viaje.valor_tarifa"}],staticClass:"form-control form-control-sm",class:_vm.$v.viaje.valor_tarifa.$invalid ? 'is-invalid' : 'is-valid',attrs:{"type":"number","placeholder":"Tarifa"},domProps:{"value":(_vm.viaje.valor_tarifa)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.viaje, "valor_tarifa", $event.target.value)},function($event){return _vm.totales()}]}})]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.viaje.total_valor_format)+" ")])]):_vm._e()],2)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header pt-1 pb-1 bg-frontera text-white"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10"},[_c('h5',{staticClass:"mb-0 p-2"},[_vm._v(" Detalle Proyección Planta Externa ")])]),_c('div',{staticClass:"col-md-2 pt-3 pb-3"},[_c('div',{staticClass:"btn-group float-right"},[_c('button',{staticClass:"btn btn-tool",attrs:{"type":"button","data-card-widget":"collapse","data-toggle":"tooltip","title":"Collapse"}},[_c('i',{staticClass:"fas fa-minus"})])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-dark"},[_c('tr',{staticClass:"text-center"},[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Acción")]),_c('th',{staticStyle:{"min-width":"15em"},attrs:{"rowspan":"2"}},[_vm._v(" Producto ")]),_c('th',{staticStyle:{"min-width":"15em"},attrs:{"rowspan":"2"}},[_vm._v(" Tipo Vehículo ")]),_c('th',{staticStyle:{"min-width":"15em"},attrs:{"rowspan":"2"}},[_vm._v(" Empresa ")]),_c('th',{staticStyle:{"min-width":"10em"},attrs:{"rowspan":"2"}},[_vm._v(" Cant Vehículos ")]),_c('th',{staticStyle:{"min-width":"10em"},attrs:{"rowspan":"2"}},[_vm._v(" Servicio T.O.V "),_c('small',[_vm._v("(BLS)")])]),_c('th',{staticStyle:{"min-width":"10em"},attrs:{"rowspan":"2"}},[_vm._v(" Galones ")]),_c('th',{attrs:{"colspan":"3"}},[_vm._v("Total")])]),_c('tr',{staticClass:"text-center"},[_c('th',{staticStyle:{"min-width":"8em"}},[_vm._v("Tarifa")]),_c('th',{staticStyle:{"min-width":"8em"}},[_vm._v("Valor")])])])
}]

export { render, staticRenderFns }